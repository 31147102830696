import Vue from 'vue'
import VueRouter from 'vue-router'

import wx from 'weixin-js-sdk';
import cookie from 'tiny-cookie'

import store from '../store'

import { getURLParameters } from "../utils";

import permission from "./permission";

import ApplicationAuthorizationService from '../service/application-authorization'
import DomainService from '../service/domain'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "index" */"../views/index.vue"),
  },
  {
    path: "/home2",
    component: () => import(/* webpackChunkName: "home2" */"../views/home2.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */"../views/404.vue"),
  },
  {
    path: "/verification",
    component: () => import(/* webpackChunkName: "verification" */"../views/verification.vue"),
  },
  {
    path: "/info",
    component: () => import(/* webpackChunkName: "info" */"../views/info.vue"),
  },
  {
    path: "/questionnaire",
    component: () => import(/* webpackChunkName: "questionnaire" */"../views/questionnaire.vue"),
  },
  {
    path: "/card",
    component: () => import(/* webpackChunkName: "card" */"../views/card.vue"),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {

  console.log(from);
  console.log(to);

  let { applicationId, code, inviter, inviteSource, source } = getURLParameters(window.location.href);

  let application = store.state.application
  let account = store.state.account
  let visitRecord = store.state.visitRecord

  const domain = process.env.NODE_ENV === "development" ? document.domain : 'ibloc.cn'

  if (inviter) {
    store.state.inviter = inviter
    cookie.set("INVITER", inviter, { domain: domain, expires: '1h' })
  } else if (cookie.get('INVITER')) {
    store.state.inviter = cookie.get('INVITER')
  }

  if (inviteSource) {
    store.state.inviteSource = inviteSource
    cookie.set("INVITER_SOURCE", inviteSource, { domain: domain, expires: '1h' })
  } else if (cookie.get('INVITER_SOURCE')) {
    store.state.inviteSource = cookie.get('INVITER_SOURCE')
  }

  if (source) {
    store.state.source = decodeURI(source)
    cookie.set("SOURCE", store.state.source, { domain: domain, expires: '1h' });
  } else if (cookie.get('SOURCE')) {
    store.state.source = cookie.get('SOURCE')
  }

  // if (from.path === to.path) {
  // next()
  // return
  // }

  const whiteList = ['404']

  if (whiteList.includes(to.name)) {
    next()
    return
  }

  try {
    //应用信息匹配
    //检查优先级 路径参数 > 当前域名 > store > localStore
    console.log(application);
    const applicationParam = {}
    if (!applicationId) {
      //路径没有
      const domain = document.domain

      console.log(domain);

      const domains = await DomainService.getDomains({ name: domain })
      if (!!domains && domains.length > 0 && domains[0].enable == 'true' && !!domains[0].application && !!domains[0].application.id) {
        //域名已绑定应用,使用域名指引
        applicationParam.id = domains[0].application.id
        await store.dispatch('getApplication', applicationParam)
      } else {
        //路径没有 ,域名未绑定
        if (!application.properties) {
          //路径没有,store没有
          if (!localStorage.getItem('application')) {
            ///路径没有,store也没有,localStore也没有
            const error = new Error('无法确定应用')
            error.code = 404
            throw error;
          } else {
            //路径没有,store也没有,local有
            applicationParam.id = localStorage.getItem('application')
            await store.dispatch('getApplication', applicationParam)
          }
        } else {
          //store有
          //不操作
          // applicationParam.id = application.id
        }
      }
      //使用其他方式获得
      if (!source) {
        // store.state.source = "SMS"
      }
    } else {
      //路径有
      applicationParam.id = to.query.applicationId
      await store.dispatch('getApplication', applicationParam)
      //路径有ID 且没source 
      if (!source) {
        // store.state.source = "OTHER"
      }
    }

    application = store.state.application

    //设置网页tilte，为应用名
    document.title = application.name || ""

    //如果是微信环境，加载微信JS配置
    if (store.state.isWeChart) {
      weichatConfig(application)
    }

    //根路径检查
    if (to.path === '/') {
      //根路径判断三方环境是否需要登录
      if (store.state.isWeChart) {
        //微信环境
        console.log("微信环境");

        if (!account.id) {
          //没有静默登录
          console.log("没有静默登录");
          if (!code) {
            //跳回登录
            console.log("没有CODE");

            const { url } = await ApplicationAuthorizationService.addApplicationAuthorizationUrl(application)

            if (url) window.location.href = url;

            return
          } else {

            console.log("有CODE");

            await store.dispatch('addAuthorization', {
              data: {
                type: "OAUTH2_AUTHORIZATION_CODE",
                params: { code },
              }
            })

            account = store.state.account

            // route(to, { path: '/' }, next);
            // return
          }
        } else {
          //静默登录了
          console.log("静默登录了");
          //是否绑定手机号
          if (!account || !account.phone) {
            //手机号未绑定
            route(to, { path: '/verification' }, next);
            return
          }
        }
      } else {
        //浏览器环境
      }
    }

    if (store.state.user.id) {
      //检查预登记进度

      try {
        await store.dispatch('getVisitRecord')

        visitRecord = store.state.visitRecord

        permission.checkVisitRecord(visitRecord, () => {
          //未验证手机号，不作处理继续访问
          next()
        }, () => {
          // 未完善信息
          route(to, { path: '/info' }, next);
        }, () => {
          //未填写问卷
          // next({ path: '/questionnaire' })
          route(to, { path: '/questionnaire' }, next);
        }, () => {
          //预登记完成
          // next({ path: '/card' })
          route(to, { path: '/card' }, next);
        })
      } catch (e) {
        console.log(e);
        route(to, { path: '/verification' }, next);
      }

      return
    }

    next();

  } catch (error) {
    console.log(error);

    if (error.code === 404 || (error.response && error.response.status === 404)) {

      // next({ path: '/404' })
      route(to, { path: '/404' }, next);
      return
    }

    if (error.code) {

      switch (error.code) {
        case "404-visitRecord":
          // next({ path: '/verification' })
          route(to, { path: '/verification' }, next);
          break;
        case "404":
          // next({ path: '/404' })
          route(to, { path: '/404' }, next);
          break;
        default:
          break;
      }

      return
    }
  }
});

const route = (to, target, next) => {

  console.log(target);
  console.log(to);
  console.log(target.path == to.path);

  if (target.path == to.path) {
    next()
  } else {
    next(target)
  }
}

const weichatConfig = async () => {

  const application = store.state.application
  const user = store.state.user
  console.log("初始化微信配置");

  const url = window.location.href

  let link = `${window.location.origin}/#/?applicationId=${application.id}`

  if (user.id) {
    link = link + `&inviter=${user.id}&inviteSource=WECHAT`
  }

  if (store.state.source) {
    link = link + `&source=${store.state.source}`
  }

  console.log(link);

  const config = await window.axios({
    url: `/application/${application.id}/wechat/config`,
    method: "POST",
    data: {
      url: url
    }
  });

  wx.config({
    debug: process.env.NODE_ENV === "development", // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: config.appId, // 必填，公众号的唯一标识
    timestamp: config.timestamp, // 必填，生成签名的时间戳
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature, // 必填，签名
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData'
    ], // 必填，需要使用的JS接口列表
  });

  wx.ready(function () {
    //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title: application.properties.shareTitle, // 分享标题
      desc: application.properties.shareDescription, // 分享描述
      link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: application.properties.shareIcon, // 分享图标
      success: function () {
        // 设置成功
        console.log("设置成功");
      },
    });

    wx.updateTimelineShareData({
      title: application.properties.shareTitle, // 分享标题
      link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: application.properties.shareIcon, // 分享图标
      success: function () {
        // 设置成功
        console.log("设置成功");
      }
    })
  });

  wx.error(function (res) {

    console.log(res);
  });
}

const preLoad = (routes) => {
  try {
    if (Array.isArray(routes)) {
      routes.forEach((item) => {
        if (typeof item.component === "function") item.component();
        if (item.children) preLoad(item.children);
      });
    }
  } catch (error) {
    console.warn("预加载出错了", error);
  }
};

window.addEventListener("load", () => preLoad(routes));

export default router
