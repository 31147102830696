export default {
    
    /**
     * 检查参观记录进度,进行不同的操作
     * @param {*} visitRecord 参展记录
     * @param {*} unVerifiedPhone 未验证手机号的回调
     * @param {*} unPerfectInformation 未完善信息的回调
     * @param {*} unRegistered 未填写问卷的回调
     * @param {*} registered 预登记完成的回调
     */
    checkVisitRecord: (visitRecord, unVerifiedPhone, unPerfectInformation, unRegistered, registered) => {
        //是否已经有参展记录
        if (visitRecord && visitRecord.id) {
            //已经有参展记录
            const questionnaires = visitRecord.properties.questionnaires;
            //参展记录-visitReocrd 有三种状态
            switch (visitRecord.progress) {
                case "VERIFIED_PHONE":
                    //VERIFIED_PHONE-已验证手机号，未完善信息
                    unPerfectInformation()
                    return;
                case "PERFECT_INFORMATION":
                    //PERFECT_INFORMATION-已完善信息
                    unRegistered()
                    return;
                case "REGISTERED":
                    //REGISTERED-注册成功
                    registered()
                    return;
                default:
                    //兼容老的数据(以后版本删除)
                    //判断是否填写用户信息问卷
                    console.log(questionnaires);
                    if (
                        questionnaires.indexOf(
                            this.application.properties.userQuestionnaireId
                        ) === -1
                    ) {
                        //没填写
                        unPerfectInformation()
                        return;
                    }
                    //判断是否填写调查问卷
                    if (
                        questionnaires.indexOf(
                            this.application.properties.questionnaireId
                        ) === -1
                    ) {
                        //没填写
                        unRegistered()
                        return;
                    }

                    //都填写了
                    registered()
            }
        } else {
            //没有参展记录
            unVerifiedPhone()
        }
    }
}