

export default {

    getUserVisitRecord: async (user, exhibition) => {

        const visitRecord = await window.axios({
            url: `/user/${user.id}/visit-record`,
            params: {
                "exhibition.id": exhibition.id
            },
        });

        return visitRecord
    },

    addUserVisitRecord: async (user, visitRecordParam) => {

        const visitRecord = await window.axios({
            url: `/user/${user.id}/visit-record`,
            method: "POST",
            data: visitRecordParam,
        });

        return visitRecord
    },

    updateUserVisitRecord: async (user, visitRecordParam) => {

        const visitRecord = await window.axios({
            url: `/user/${user.id}/visit-record/${visitRecordParam.id}`,
            method: "PATCH",
            data: visitRecordParam,
        });

        return visitRecord
    },

    updateVisitRecord: async (visitRecordParam) => {

        const visitRecord = await window.axios({
            url: `/visit-record/${visitRecordParam.id}`,
            method: "PATCH",
            data: visitRecordParam,
        });

        return visitRecord
    },
}