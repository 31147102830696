export const getURLParameters = (url) =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      (a[v.slice(0, v.indexOf("="))] = v
        .slice(v.indexOf("=") + 1)
        .replace(/#.*/g, "")),
      a
    ),
    {}
  );

export const isWeChart = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  }
  return false;
};

export const showLoading = () => {
  const loading = document.querySelector(".loader");
  if (loading) {
    loading.style.display = "block";
  }
};

export const hideLoading = () => {
  const loading = document.querySelector(".loader");
  if (loading) {
    loading.style.display = "none";
  }
};
