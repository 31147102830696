<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
html,
body,
#app {
  min-width: 100vw;
  min-height: 100vh;
  font-family: PingFangSC-Regular, PingFang SC;
}

body {
  margin: 0;
   /*解决ios上滑动不流畅*/
   -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar {
  display: none;
}

.van-uploader__input-wrapper {
  width: 100% !important;
}
</style>
